

















































import Vue from "vue";
import {mapActions, mapGetters} from "vuex";
import {required, sameAs, minLength} from "vuelidate/lib/validators";

export default Vue.extend({
  name: "user-change-password" as string,

  data() {
    return {
      title: "Change password",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isSubmitted: false
    }
  },

  //@ts-ignore
  validations: {
    oldPassword: { required, minLength: minLength(6) },
    newPassword: { required, minLength: minLength(6) },
    confirmNewPassword: { required, minLength: minLength(6), sameAsPassword: sameAs('newPassword') },
  },

  computed: {
    ...mapGetters("userModule", {
      loader: "GET_LOADER"
    })
  },

  methods: {
    ...mapActions("userModule", {
       changeUserPassword:"CHANGE_USER_PASSWORD"
    }),
    async changePassword(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        password: this.oldPassword,
        new_password: this.newPassword
      }

      await this.changeUserPassword(payload);

      this.isSubmitted = false;
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    }
  }
})
