




































































































import Vue, {PropType} from "vue";
import {required, minLength, maxLength, email} from "vuelidate/lib/validators";
import {UserData} from "@/types";
import vSelect from "vue-select";

export default Vue.extend({
  name: "user-info" as string,

  props: {
    user: {} as PropType<UserData>,
    edit: Boolean as PropType<Boolean>,
    admin: Boolean as PropType<Boolean>,
  },
  components: {
    vSelect,
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      jobPosition: "",
      isSubmitted: false,
      role:""
    }
  },

  //@ts-ignore
  validations: {
    firstName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    lastName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    email: { required, email },
    phoneNumber: {
      checkPhoneNumber(phoneNumber) {
        if(phoneNumber === null || phoneNumber === '') return true;
        return (/^[+\d](?:.*\d|\s*)(?:.*\d|\s*)?$/.test(phoneNumber));
      }
    }
  },

  async mounted(): Promise<void> {
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
    this.email = this.user.email;
    this.phoneNumber = this.user.phone_number;
    this.jobPosition = this.user.job_position;
  },

  methods: {
    emitNewUserData(): void {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit("validate", !this.$v.$invalid);
        return;
      }

      const userPayload = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.phoneNumber ? this.phoneNumber : "",
        job_position: this.jobPosition ? this.jobPosition : ""
      }

      this.$emit("newUserData", userPayload);
      this.$emit("validate", !this.$v.$invalid);
    }
  }
})
