























import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import {UserData} from "@/types";
import createImage from "@/mixins/CreateImage";
import {successMessage} from "@/utils/messages-handlers";

export default Vue.extend({
  name: "user-avatar" as string,

  data() {
    return {
      title: "Change your Avatar",
      item: false,
      avatarChanged: false
    }
  },

  mixins: [createImage],

  props: {
    user: {} as PropType<UserData>
  },

  computed: {
    ...mapGetters("userModule", {
      isUserImage: "IS_USER_IMAGE",
      userImage: "GET_USER_IMAGE"
    }),
    formatImage(): string {
      if(!this.item) return "";
      return this.item.split(',')[1];
    },
    insertUserImage(): string {
      if (!this.userImage) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.userImage}`
    }
  },

  methods: {
    ...mapActions("userModule", {
      updateUser: "UPDATE_USER"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0],".user-profile-avatar");

      setTimeout(() => {
        this.avatarChanged = true;
      }, 250)
    },
  },

  watch: {
    avatarChanged: {
      async handler(value: boolean): Promise<void> {
        if (value) {
          await this.updateUser({ picture: this.formatImage} );
          await this.setImage({id: this.user.id, imageType: "clientzone_user"});

          this.avatarChanged = false;
          successMessage("User avatar updated.", 3000);
        }
      }
    }
  }
})
